import React from 'react';
import { Box, Typography } from '@mui/material';

const Terms: React.FC = () => {
  return (
    <Box 
      display="flex" 
      flexDirection="column" 
      justifyContent="center" 
      height="100vh"
      textAlign="left"
      ml="3rem" // Margin from the left side
    >
      <Typography variant="h1">BT2</Typography>
      <Typography variant="h2">A BETTER TABLETOP TOOL</Typography>
      <Typography variant="h3">Coming soon to a basement near you</Typography>
    </Box>
  );
};

export default Terms;

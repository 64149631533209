import React from 'react';
import { Box, CssBaseline, GlobalStyles, Link } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Routes, Route, Link as RouterLink } from 'react-router-dom';

import Privacy from './components/privacy';
import Terms from './components/terms';
import Home from './components/home';
import Delete from './components/delete';
import NotFound from './components/not-found';

const theme = createTheme({
  typography: {
    fontFamily: 'Roboto, sans-serif',
    h1: {
      fontSize: '4rem',
      marginBottom: '2.0rem',
      color: '#dbff00', // Header font color
    },
    h2: {
      fontSize: '1.5rem',
      color: '#b3bab6', // Header font color
    },
    h3: {
      fontSize: '1.3rem',
      marginTop: '1.0rem',
      color: '#b3bab6', // Header font color
    },
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          color: '#ffe0e0',
          marginRight: '1rem',
          marginLeft: '1rem',
          textDecoration: 'none',
          ":hover": {
            textDecoration: "underline",
          },
          '&:last-child': {
            marginRight: 0,
          },
        },
      },
    },
  },
});

const App: React.FC = () => {
  const publicUrl = process.env.PUBLIC_URL ?? '';

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyles styles={{ body: { backgroundColor: '#011a0b', margin: 0 } }} /> {/* Background color */}
      <Box flex="1">
      <Routes>
        <Route path={`${publicUrl}/`} element={<Home />} />
        <Route path={`${publicUrl}/privacy`} element={<Privacy />} />
        <Route path={`${publicUrl}/terms`} element={<Terms />} />
        <Route path={`${publicUrl}/delete`} element={<Delete />} />
        <Route path={`${publicUrl}/*`} element={<NotFound />} />
      </Routes>
      </Box>
        <Box 
          position="fixed" 
          bottom="1.0rem" 
          width="100vw"
          display="flex"
          alignItems="flex-end"
          bgcolor="#011a0b"
          p="1rem"
        >
          <Link component={RouterLink} to={`${publicUrl}/`}>Home</Link>
          <Link component={RouterLink} to={`${publicUrl}/privacy`}>Privacy Policy</Link>
          <Link component={RouterLink} to={`${publicUrl}/terms`}>Terms and Conditions</Link>
          <Link component={RouterLink} to={`${publicUrl}/delete`}>Delete your account</Link>
        </Box>
    </ThemeProvider>
  );
};

export default App;

import React from 'react';
import { Container, List, ListItem, Typography } from '@mui/material';

const Delete: React.FC = () => {
  return (
    <Container 
        sx={{
            ml: '0.4rem',
            mr: '50px',
            mb: '150px',
            width: '90vw'
        }}
    >
    <Typography variant="h1">Delete your account</Typography>
    <Typography variant="h3">
    <List sx={{ listStyleType: 'disc', ml: '2rem' }}>
        <ListItem sx={{ display: 'list-item' }}>Open the Bt2 App</ListItem>
        <ListItem sx={{ display: 'list-item' }}>Click on the ‘Gear’ icon to go to the Settings page</ListItem>
        <ListItem sx={{ display: 'list-item' }}>Login if not currently logged in</ListItem>
        <ListItem sx={{ display: 'list-item' }}>Click the ‘Account Details’ button</ListItem>
        <ListItem sx={{ display: 'list-item' }}>Click the ‘Delete Account’ button</ListItem>
        <ListItem sx={{ display: 'list-item' }}>Enter the email you used to sign in</ListItem>
        <ListItem sx={{ display: 'list-item' }}>Click the ‘Delete Account’ button</ListItem>
        <ListItem sx={{ display: 'list-item' }}>All of your data will be deleted within 24 hours</ListItem>
    </List>
    </Typography>
    </Container>
  );
};

export default Delete;
